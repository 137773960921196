
import './App.css';
import Router from "./Routes/routes"

function App() {
  return (
   <body 
   style={{ cursor: 'url(images/pokebola.png), auto'}}>
   <Router/>
   </body>
  );
}

export default App;
